import React from "react";
import Navbar from "./Components/Navbar";
import Login from "./Components/Login";
import AdvancedSaasVisibility from "./Components/AdvancedSaasVisibility";
import DiscoveredUsers from "./Components/DiscoveredUsers";
import AppCatalog from "./Components/AppCatalog";
import SecurityRules from "./Components/SecurityRules";
import ExecutiveReports from "./Components/ExecutiveReports";
import UsersList from "./Components/UsersList";
import UserShow from "./Components/UserShow";
import UserNew from "./Components/UserNew";
import UserEdit from "./Components/UserEdit";
import Footer from "./Components/Footer";
import PrivateRoute from "./hocs/PrivateRoute";
import UnPrivateRoute from "./hocs/UnPrivateRoute";
import { Route, Redirect } from "react-router-dom";
import AppList from "Components/AppList";
import AppDetails from "Components/AppDetails";
import Logout from "Components/Logout";
import NewSecurityRule from "Components/NewSecurityRule";
import EditSecurityRule from "Components/EditSecurityRule";
import CloneSecurityRule from "Components/CloneSecurityRule";
import SaasVisibilitySettings from "Components/SaasVisibilitySettings";
import Licenses from "Components/Licenses";

function App() {
  return (
    <div>
      <Navbar />
      <UnPrivateRoute path="/d/users/sign_in" component={Login} />
      <UnPrivateRoute path="/d/users/sign_out" component={Logout} />
      <Route exact path="/">
        <Redirect to="/d/users/sign_in" />
      </Route>

      <div className="container-fluid" id="main-container">
        <div className="row">
          <div className="col-md-12">
            <PrivateRoute
              path="/advanced_saas_visibility"
              roles={["user", "admin"]}
              component={AdvancedSaasVisibility}
            />

            <PrivateRoute
              path="/app_list"
              roles={["user", "admin"]}
              component={AppList}
            />

            <PrivateRoute
              path="/app_details/:id"
              roles={["user", "admin"]}
              component={AppDetails}
            />

            <PrivateRoute
              path="/discovered_users"
              roles={["user", "admin"]}
              component={DiscoveredUsers}
            />

            <PrivateRoute
              path="/app_catalog"
              roles={["user", "admin"]}
              component={AppCatalog}
            />

            <PrivateRoute
              exact
              path="/security_rules"
              roles={["user", "admin"]}
              component={SecurityRules}
            />

            <PrivateRoute
              exact
              path="/security_rules/new"
              roles={["user", "admin"]}
              component={NewSecurityRule}
            />

            <PrivateRoute
              path="/security_rules/:id/edit"
              roles={["user", "admin"]}
              component={EditSecurityRule}
            />

            <PrivateRoute
              path="/security_rules/:id/clone"
              roles={["user", "admin"]}
              component={CloneSecurityRule}
            />

            <PrivateRoute
              path="/saas_visibility/executive_reports"
              roles={["user", "admin"]}
              component={ExecutiveReports}
            />
            <PrivateRoute
              exact
              path="/users"
              roles={["admin"]}
              component={UsersList}
            />
            <PrivateRoute
              exact
              path="/users/new"
              roles={["admin"]}
              component={UserNew}
            />
            <PrivateRoute
              exact
              path="/users/:id"
              roles={["admin"]}
              component={UserShow}
            />
            <PrivateRoute
              path="/users/:id/edit"
              roles={["admin"]}
              component={UserEdit}
            />
            <PrivateRoute
              exact
              path="/saas_visibility_settings"
              roles={["admin"]}
              component={SaasVisibilitySettings}
            />
            <PrivateRoute
              exact
              path="/license"
              roles={["admin"]}
              component={Licenses}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
