import React, { useEffect } from "react";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

import { UserShow as AdminShow, LeftNav } from "@radon/ui-components";

const UserShow = (props) => {
  useEffect(() => {}, []);
  const {
    match: { params },
  } = props;
  return (
    <div className="row isFlex">
      <div id="leftNav" className="col-md-3 no-padding white-smoke-background">
        <LeftNav
          navigationData={JSON.stringify({
            Application: [
              {
                title: "Admin Accounts",
                path: "/users",
                isActive: "active",
              },
            ],
          })}
        />
      </div>
      <div className="col-md-9">
        <AdminShow userId={params.id} getPath={"/user"} listPath={"/users"} />
      </div>
    </div>
  );
};

export default UserShow;
