import React, { useEffect } from "react";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

import { UserList as AdminAccounts, LeftNav } from "@radon/ui-components";

const UsersList = (props) => {
  useEffect(() => {}, []);

  return (
    <div className="row isFlex">
      <div id="leftNav" className="col-md-3 no-padding white-smoke-background">
        <LeftNav
          navigationData={JSON.stringify({
            Application: [
              {
                title: "Admin Accounts",
                path: "/users",
                isActive: "active",
              },
              {
                title: "SaaS Visibility",
                path: "/saas_visibility_settings",
              },
              {
                title: "License Info",
                path: "/license",
              },
            ],
          })}
        />
      </div>
      <div className="col-md-9 margin-top-xlg">
        <AdminAccounts
          appName={"My App Name"}
          canCreate={true}
          asvOnly={true}
          roles={[
            { label: "Role 1", key: "role-id1" },
            { label: "Role 2", key: "role-id2" },
            { label: "Role 3", key: "role-id3" },
            { label: "Role 4", key: "role-id4" },
            { label: "Role 5", key: "role-id5" },
            { label: "Role 6", key: "role-id6" },
          ]}
          columns={{
            name: "Name",
            email: "Email",
            auth_type: "Authentication Type",
            role: "Role",
            actions: "Actions",
            team: "Team",
          }}
          showAuthType={true}
          showTeams={true}
          paths={{
            // GET
            list: "/users",
            // GET
            new: "/users/new",
            // GET
            edit: "/users",
            // GET
            show: "/users",
            // POST
            create: "/users",
            // DELETE, /users/:id
            delete: "/users",
            // PUT, /users/:id
            update: "/users",
          }}
        />
      </div>
    </div>
  );
};

export default UsersList;
