import React, { useEffect } from "react";

import { AppList as DiscoveredApps } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const AppList = (props) => {
  useEffect(() => {}, []);

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <React.Fragment>
      <div className="container-fluid row">
        <DiscoveredApps
          duration={queryParams.get("duration")}
          filterCategory={queryParams.get("category")}
          filterRisk={queryParams.get("risk")}
          filterSanctionedStatus={queryParams.get("sanctionedStatus")}
          svVerified={true}
          updateStatusPermission={true}
          enableUsers={true}
          enableCustomTagging={true}
          enableManualRiskScoring={true}
          enableCustomRiskScoring={true}
          enableRules={true}
          enableDictionary={true}
        />
      </div>
    </React.Fragment>
  );
};

export default AppList;
