import React, { useEffect } from "react";
// import { AuthContext } from "../Context/AuthContext";
// import Dashboard from "./AdvancedSaasVisibility/Dashboard/Dashboard";

import {
  AppsOverview,
  AppsByRisk,
  AppsByTopCategory,
  TopApps,
  AsvNav,
} from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const AdvancedSaasVisibility = (props) => {
  useEffect(() => {}, []);
  return (
    <div className="advancedSaasVisibilityDashboard">
      <div className="container-fluid row">
        <div className="advancedSaasVisibilityDashboardHeader row">
          <AsvNav
            enableUsers={true}
            enableRules={true}
            enableDictionary={true}
            svVerified={true}
          />
          <div>
            <div className="col-xs-12 pageTitle">
              SaaS Visibility <div className="betaTag">Beta</div>
            </div>
            <div className="col-xs-12 pageTitleSubtext">
              This page summarizes the overall usage of SaaS applications that
              are in use in your organization.
            </div>
          </div>
        </div>
      </div>
      <div className="dashboardContent">
        <div>
          <AppsOverview enableUsers={true} />
          <div className="col-md-6 leftPanelPadding">
            <AppsByRisk />
            <AppsByTopCategory />
          </div>
          <div className="col-md-6 rightPanelPadding">
            <TopApps enableRules={true} />
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSaasVisibility;
