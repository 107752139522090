import React, { useEffect } from "react";

import { SaasVisibilitySettings as SaasVisibilitySettingsView } from "@radon/ui-components";
import { LeftNav } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

// REMOVES WHEN BACKEND IT IS READY
const tenantDetail = {
  serial_number_lgs: null,
  status: "error",
  error: false,
  customer_support_id: null,
};

const actions = {
  canManage: true,
  canDestroy: true,
  canRead: true,
};

const SaasVisibilitySettings = (props) => {
  useEffect(() => {}, []);

  return (
    <div className="row isFlex">
      <div id="leftNav" className="col-md-3 no-padding white-smoke-background">
        <LeftNav
          navigationData={JSON.stringify({
            Application: [
              {
                title: "Admin Accounts",
                path: "/users",
              },
              {
                title: "SaaS Visibility",
                path: "/saas_visibility_settings",
                isActive: "active",
              },
              {
                title: "License Info",
                path: "/license",
              },
            ],
          })}
        />
      </div>
      <div className="col-md-9">
        <SaasVisibilitySettingsView
          tenantDetail={tenantDetail}
          loggingServiceSetting={null}
          actions={actions}
          licenses={"1234"}
          appName={"Palo Alto Networks"}
        />
      </div>
    </div>
  );
};

export default SaasVisibilitySettings;
