import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      path={rest.path}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/d/users/sign_in",
                state: { from: props.location },
              }}
            />
          );
        }

        // if (!roles.includes(user.role))
        //     return (
        //         <Redirect
        //             to={{
        //                 pathname: "/d/users/sign_in",
        //                 state: { from: props.location },
        //             }}
        //         />
        //     );
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
