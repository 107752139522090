import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { CloneSecurityRule as SecurityRulesForm } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const CloneSecurityRule = () => {
  let { id } = useParams();

  const [securityRule, setSecurityRule] = useState(null);
  const [error, setErrors] = useState(false);

  useEffect(() => {
    axios
      .get(`/security_rules/${id}.json`)
      .then((res) => {
        if (res.status !== 200) {
          setErrors(true);
        } else {
          setSecurityRule(res.data);
        }
      })
      .catch(() => {
        setErrors(true);
      });
  }, [id]);

  if (error || !securityRule) {
    return null;
  }

  return <SecurityRulesForm enableActiveDirectory={true} rule={securityRule} />;
};

export default CloneSecurityRule;
