import React, { useEffect } from "react";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

import { UserNew as AdminNew, LeftNav } from "@radon/ui-components";

const UserNew = (props) => {
  useEffect(() => {}, []);
  const {
    match: { params },
  } = props;
  return (
    <div className="row isFlex">
      <div id="leftNav" className="col-md-3 no-padding white-smoke-background">
        <LeftNav
          navigationData={JSON.stringify({
            Application: [
              {
                title: "Admin Accounts",
                path: "/users",
                isActive: "active",
              },
            ],
          })}
        />
      </div>
      <div className="col-md-9">
        <AdminNew
          flags={{
            isSso: false,
            isPanSso: false,
            groupBasedVisibility: false,
          }}
          paths={{
            list: "/users",
            create: "/user",
          }}
        />
      </div>
    </div>
  );
};

export default UserNew;
