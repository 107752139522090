import React, { useEffect } from "react";

import { NewSecurityRule as SecurityRulesForm } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const NewSecurityRule = (props) => {
  useEffect(() => {}, []);

  return <SecurityRulesForm enableActiveDirectory={true} />;
};

export default NewSecurityRule;
