import React, { useEffect } from "react";

import { AppDetails as ApplicationDetails } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const AppList = (props) => {
  useEffect(() => {}, []);

  const {
    match: { params },
  } = props;

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <React.Fragment>
      <div className="container-fluid row">
        <ApplicationDetails
          id={params.id}
          app={queryParams.get("app")}
          duration={queryParams.get("duration")}
          enableUsers={true}
          enableManualRiskScoring={true}
          enableCustomRiskScoring={true}
          enableRules={true}
          enableCustomTagging={true}
          enableDictionary={true}
        />
      </div>
    </React.Fragment>
  );
};

export default AppList;
