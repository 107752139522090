import React, { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { useLocation } from "react-router-dom";

import { TopNav } from "@radon/ui-components";

const Navbar = (props) => {
  const { isAuthenticated, user } = useContext(AuthContext);

  let location = useLocation();
  const currentPath = location.pathname;

  const navItems = [
    {
      class: currentPath === "/advanced_saas_visibility" ? "active" : null,
      name: "Visibility",
      path: "/advanced_saas_visibility",
      subMenu: [],
    },
    {
      class:
        currentPath === "/saas_visibility/executive_reports" ? "active" : null,
      name: "Reports",
      path: "/saas_visibility/executive_reports",
      subMenu: [],
    },
    {
      class: currentPath.startsWith("/users") ? "active" : null,
      name: "Settings",
      path: "/users",
      subMenu: [],
    },
  ];
  return (
    <React.Fragment>
      <TopNav
        user={user}
        navItems={isAuthenticated ? navItems : []}
        showAppSwitcher={false}
        signOutPath={"/d/users/sign_out"}
        hideUserMenu={isAuthenticated ? false : true}
        appTitle="Prisma SaaS"
      />
    </React.Fragment>
  );
};

export default Navbar;
