const axios = require("axios");

const authService = {
    login: (user) => {
        return axios.post(`/user/login`, JSON.stringify(user)).then((res) => {
                if (res.status !== 401) return res.data;
                else
                    return {
                        isAuthenticated: false,
                        user: { username: "", role: "" },
                    };
            });
    },
    logout: () => {
        return axios.get(`/user/logout`).then((res) => {
                return res.data;
            });
    },
    isAuthenticated: () => {
        return axios.get(`/user/authenticated`).then((res) => {
                if (res.status !== 401) {
                    return res.data;
                } else {
                    return {
                        isAuthenticated: false,
                        user: { username: "", role: "" },
                    };
                }
            });
    },
};

export default authService;
