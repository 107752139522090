import React, { useEffect } from "react";

import { DiscoveredUsers as DiscoveredUsersList } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const DiscoveredUsers = (props) => {
  useEffect(() => {}, []);

  const queryParams = new URLSearchParams(props.location.search);

  return (
    <DiscoveredUsersList
      svVerified={true}
      enableUsers={true}
      enableRules={true}
      enableDictionary={true}
      duration={queryParams.get("duration")}
      appId={queryParams.get("appId")}
      appName={queryParams.get("appName")}
      canDownload={true}
    />
  );
};

export default DiscoveredUsers;
