import React from "react";
import "../App.scss";
import PaloAltoNetworksIcon from "./PaloAltoNetworksIcon";

const Footer = () => (
  <div className="footer" id="footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10">
          <div>0.0.radon.15 </div>
        </div>
        <div className="col-md-2 logo">
          <PaloAltoNetworksIcon width={120} height={27} />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
