import React, { useEffect } from "react";
import {
  ExecutiveReports as SaasInlinePdfReport,
  LeftNav,
} from "@radon/ui-components";
import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";
import { Alert } from "@radon/ui-components";

const ExecutiveReports = (props) => {
  useEffect(() => {}, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Alert />
        </div>
      </div>
      <div className="row isFlex">
        <div
          id="leftNav"
          className="col-md-3 no-padding white-smoke-background"
        >
          <LeftNav
            navigationData={JSON.stringify({
              Reports: [
                {
                  title: "SaaS Inline Report",
                  path: "/saas_visibility/executive_reports",
                  isActive: "active",
                },
              ],
            })}
          />
        </div>
        <div className="col-md-9">
          <SaasInlinePdfReport
            generateButton={{
              // permission: current user is allowed to generate reports.
              canGenerateReport: true,
              ranges: [7, 30, 90],
            }}
            quotaExceeded={{
              // this information must come from the API.
              exceeded: false,
              userName: "Zeeshan",
              // this information must come from the API.
              max: 10,
            }}
            paths={{
              reports: "/saas-visibility/v1/reports",
              generate: "/saas-visibility/v1/generate_report",
              retry: "/saas-visibility/v1/report",
              // method delete
              delete: "/saas-visibility/v1/report",
              // this must return data
              pdf: "/inline/saas-visibility/v1/report",
              user: "/saas-visibility/v1/userdata",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExecutiveReports;
