import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import AuthProvider from "./Context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";

const ROUTE_PREFIX = process.env.REACT_APP_ROUTE_PREFIX;
axios.defaults.headers.get["Accept"] = "application/json"; // default header for all get request
axios.defaults.headers.post["Accept"] = "application/json"; // default header for all POST request
axios.defaults.headers["Content-Type"] = "application/json";
axios.interceptors.request.use((config) => {
  if (config.url && config.url.indexOf("/inline/") < 0) {
    config.url = `${ROUTE_PREFIX}${config.url}`;
  }
  config.headers.domain = window.location.hostname;
  return config;
});
ReactDOM.render(
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>,
  document.getElementById("general-container")
);
