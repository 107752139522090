import React, { useEffect } from "react";

import { SecurityRules as SecurityRulesList } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const SecurityRules = (props) => {
  useEffect(() => {}, []);

  return (
    <SecurityRulesList
      svVerified={true}
      canDownload={true}
      enableUsers={true}
      enableRules={true}
      enableDictionary={true}
    />
  );
};

export default SecurityRules;
