import React, { useEffect } from "react";

import { Licenses as LicensesView } from "@radon/ui-components";
import { LeftNav } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const Licenses = (props) => {
  useEffect(() => {}, []);

  return (
    <div id="main-column">
      <div className="row isFlex">
        <div
          id="leftNav"
          className="col-md-3 no-padding white-smoke-background"
        >
          <LeftNav
            navigationData={JSON.stringify({
              Application: [
                {
                  title: "Admin Accounts",
                  path: "/users",
                },
                {
                  title: "SaaS Visibility",
                  path: "/saas_visibility_settings",
                },
                {
                  title: "License Info",
                  path: "/license",
                  isActive: "active",
                },
              ],
            })}
          />
        </div>
        <div id="licenses" className="col-md-9 margin-top-xlg">
          <LicensesView />
        </div>
      </div>
    </div>
  );
};

export default Licenses;
