import { useContext } from "react";
import AuthService from "../Services/AuthService";
import { AuthContext } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const { setIsAuthenticated, setUser } = useContext(AuthContext);
  let history = useHistory();
  AuthService.logout().then((data) => {
    if (data.success) {
      setUser(data.user);
      setIsAuthenticated(false);
      history.replace("/d/users/sign_in");
    }
  });

  return null;
};

export default Logout;
