import React, { useState, useContext } from "react";
import AuthService from "../Services/AuthService";
import Message from "../Components/Message";
import { AuthContext } from "../Context/AuthContext";
import "../assets/styles/bootstrap.scss";
import "../assets/styles/Login.scss";

const Login = (props) => {
    const [user, setUser] = useState({});
    const [message, setMessage] = useState(null);
    const authContext = useContext(AuthContext);

    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const signIn = (e) => {
        e.preventDefault();
        AuthService.login(user).then((data) => {
            const { isAuthenticated, user, message } = data;
            if (isAuthenticated) {
                authContext.setUser(user);
                authContext.setIsAuthenticated(isAuthenticated);
                props.history.push("/advanced_saas_visibility");
            } else setMessage(message);
        });
    };

    return (
        <div className="login-page row" style={{ marginTop: "100px" }}>
            <div className="col-md-12 position-static">
                <div className="main-column" id="signin-page">
                    <div className="panel panel-default panel-centered">
                        <div className="panel-heading" id="heading-signIn">
                            <h1 className="panel-title">Log in</h1>
                        </div>
                        <div className="panel-body">
                            <div id="data-base-login">
                                <form
                                    className="simple_form form-condensed"
                                    noValidate="novalidate"
                                    id="new_user"
                                    acceptCharset="UTF-8"
                                    onSubmit={signIn}
                                >
                                    <div className="form-group email optional user_email">
                                        <label
                                            htmlFor="username"
                                            className="sr-only"
                                        >
                                            Username:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="username"
                                            onChange={onChange}
                                            className="form-control"
                                            placeholder="Enter Username"
                                        />
                                        <label
                                            htmlFor="password"
                                            className="sr-only"
                                        >
                                            Password:{" "}
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            onChange={onChange}
                                            className="form-control"
                                            placeholder="Enter Password"
                                        />
                                        <input
                                            type="hidden"
                                            name="authenticity_token"
                                            value="q9iQb8+K/W1mc4etzIzODz2cWLaaURSWPaCDG0h26HCkuXgVnR0bgYXJ+hTUmcSPkmi5rvVVOzs+LpZ1O1FPHw=="
                                        ></input>
                                    </div>
                                    <button
                                        className="btn btn-lg btn-primary btn-block"
                                        type="submit"
                                    >
                                        Log in{" "}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {message ? <Message message={message} /> : null}
        </div>
    );
};

export default Login;
