import React, { useEffect } from "react";

import { AppCatalog as ApplicationDictionary } from "@radon/ui-components";

import "@radon/ui-components/dist/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../assets/styles/bootstrap.scss";
import "../App.scss";

const AppCatalog = (props) => {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="row">
        <ApplicationDictionary
          svVerified={true}
          enableUsers={true}
          enableRules={true}
          enableDictionary={true}
          enableCustomRiskScoring={true}
        />
      </div>
    </React.Fragment>
  );
};

export default AppCatalog;
